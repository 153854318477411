import React from 'react';
import styled from 'styled-components';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as Ant from 'antd';
import Theme from '../../Theme';
import {calcMembershipPrice} from './util';

function MemberNotice(props) {
  const [article, setArticle] = React.useState(null);
  const [modalData, setModalData] = React.useState({
    visible: false,
  });

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setArticle(
          await JStorage.fetchOneDocument(
            'Article_Default',
            {id: '66dfff023a4cd2c5ac52792f'},
            null,
            {
              token: null,
            },
          ),
        );
      } catch (ex) {
        console.warn('REV_DBG', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, []);

  return (
    <Wrapper>
      {article && (
        <>
          <h1>{article.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: article.raw_html?.enabled
                ? article.raw_html.html
                : article.html,
            }}
          />
        </>
      )}

      <Ant.Button
        style={{marginTop: 20}}
        type="primary"
        onClick={() => {
          setModalData({
            visible: true,
          });
        }}>
        試算會費
      </Ant.Button>

      <CalcPriceModal
        close={() => setModalData({visible: false})}
        {...modalData}
      />
    </Wrapper>
  );
}

function CalcPriceModal(props) {
  const {visible, close} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      open={visible}
      onOk={close}
      onCancel={close}
      destroyOnClose={true}>
      {visible && <ModalContent {...props} />}
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const [applyType, setApplyType] = React.useState('new');
  const [dateStr, setDateStr] = React.useState(
    new Date().toLocaleDateString('sv'),
  );
  const [estimatedPrice, setEstimatedPrice] = React.useState(null);

  React.useEffect(() => {
    setEstimatedPrice(null);
  }, [dateStr, applyType]);

  return (
    <ModalWrapper>
      <h2 style={{marginBottom: 30}}>試算會費</h2>
      <div className="field">
        <label>申請類別</label>
        <Ant.Radio.Group
          value={applyType}
          onChange={(e) => {
            setApplyType(e.target.value);
          }}>
          <Ant.Radio value="new">新申請入會</Ant.Radio>
          <Ant.Radio value="renew">重行申請入會</Ant.Radio>
        </Ant.Radio.Group>
      </div>

      <div className="field">
        <label>入會日期</label>
        <Ant.Input
          type="date"
          value={dateStr}
          onChange={(e) => {
            setDateStr(e.target.value);
          }}
        />
      </div>

      <div style={{display: 'flex'}}>
        <Ant.Button
          type="primary"
          onClick={() => {
            const calcResult = calcMembershipPrice({applyType, dateStr});
            setEstimatedPrice(calcResult);
          }}>
          試算
        </Ant.Button>
        <Ant.Button
          type="link"
          onClick={() => {
            setEstimatedPrice(null);
          }}>
          清除
        </Ant.Button>
      </div>

      {estimatedPrice && (
        <div
          style={{
            marginTop: 20,
            padding: '20px 0',
            borderTop: '1px solid #ccc',
          }}>
          <h2 style={{color: Theme.colors.main}}>
            總金額: ${estimatedPrice.totalPrice}
          </h2>
          {applyType === 'new' ? (
            <div
              style={{
                color: '#888',
              }}>
              {`新申請入會費: $${estimatedPrice.basePrice} + 當年度常年會費${estimatedPrice.regularMonths}個月 $${estimatedPrice.regularMonthsPrice}`}
            </div>
          ) : (
            <div
              style={{
                color: '#888',
              }}>{`重行申請入會費 $${
              estimatedPrice.basePrice - 500
            } + 證書費 $500 + 當年度常年會費${
              estimatedPrice.regularMonths
            }個月 $${estimatedPrice.regularMonthsPrice}`}</div>
          )}
        </div>
      )}
    </ModalWrapper>
  );
}

const Wrapper = styled.div`
  h1 {
    text-align: center;
    padding-bottom: 15px;
    border-bottom: 4px solid ${Theme.colors.main};
    margin-bottom: 25px;
  }
`;

const ModalWrapper = styled.div`
  padding: 20px;
  div.field {
    margin-bottom: 30px;
    & > label {
      display: block;
      margin-bottom: 6px;
      &::before {
        content: '*';
        color: red;
      }
    }
  }
`;

export default withPageEntry(MemberNotice);
