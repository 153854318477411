export function calcMembershipPrice(data) {
  const {applyType, dateStr} = data;
  const [y, m, d] = dateStr.split('-').map((it) => parseInt(it, 10));
  const monthsLeftPrice = (12 - m) * 1500;
  const partialMonth = d > 15;
  const thisMonthPrice = partialMonth ? 750 : 1500;
  const basePrice = applyType === 'new' ? 9000 : 5000;
  return {
    basePrice,
    regularMonths: 12 - m + 1 - (partialMonth ? 0.5 : 0),
    regularMonthsPrice: monthsLeftPrice + thisMonthPrice,
    totalPrice: basePrice + monthsLeftPrice + thisMonthPrice,
  };
}
